.App {
    /* text-align: center; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html,
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.spacematch_title {
    /* margin:auto; */
}

.main_icon_paper {
    max-width: 200px;
    /* height: 150px; */
    /* margin-top: 10vh; */
}

.main_icon_image {
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #00f0c4;
    /* border-bottom: 2px solid #ed4c73; */
}

.MuiToolbar-regular {
    min-height: 200px;
}

div#list_space_text {
    margin: 43px 0px;
}

p.MuiTypography-root.MuiTypography-h5.MuiTypography-colorTextSecondary {
    margin-top: 5vh;
}


/* .App {
  margin-top: 20px;
  position: relative;
  top: 46px;
} */

.search_bar {
    position: relative;
    bottom: 80%;
    padding: 26px;
    border-radius: 5px;
    background-color: #ffffffb3;
}

.App {
    /* margin-top: 7%; */
}

@media only screen and (max-width: 3000px) {
    .sidebarmap {
        position: fixed;
        right: 0px;
        top: 0px;
        width: 50%;
    }
}

@media only screen and (max-width: 1000px) {
    .sidebarmap {
        position: relative;
        width: 100%;
    }
}

.sv_main {
    background-color: coral;
}

g.raphael-group-UkqWWrjq {
    display: none;
}